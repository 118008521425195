<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex class="login-form text-xs-center">
        <v-card light>
          <v-card-text>
            <v-alert v-if="!!errorMessage" icon="mdi-alert" type="error">
              {{ errorMessage }}
            </v-alert>
            <v-form v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                light="light"
                prepend-icon="mdi-email"
                label="Email"
                type="email"
              ></v-text-field>
              <v-text-field
                v-model="user.password"
                light="light"
                prepend-icon="mdi-lock"
                label="Password"
                type="password"
              ></v-text-field>
              <v-btn
                class="mt-4"
                color="accent"
                block
                elevation="0"
                v-on:click="onSignIn"
              >
                Sign In
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "LoginForm",
  data: () => ({
    errorMessage: "",
    user: {
      email: "",
      password: "",
    },
    valid: true,
    emailRules: [
      (v) => !!v || "Sign in with your registered email address",
      (v) => /.+@.+\..+/.test(v) || "Invalid email address",
    ],
  }),
  props: {
    error: String,
  },
  watch: {
    error(msg) {
      this.errorMessage = msg;
    },
  },
  methods: {
    onSignIn() {
      if (this.valid && this.user.email && this.user.password) {
        this.$emit("sign-in", this.user);
      } else {
        this.errorMessage =
          "Sign in with your registered email address and password.";
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  max-width: 480px;
}
</style>
