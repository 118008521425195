<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon v-on:click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span>{{ displayName }}</span>
        <span class="font-weight-light text-uppercase">Management Console</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$store.getters.user.signedIn"
        v-on:click="onSignOut"
        color="transparent"
        elevation="0"
      >
        <v-icon left>mdi-exit-to-app</v-icon>
        SIGN OUT
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" width="320" absolute temporary>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ accountName }}</v-list-item-title>
            <v-list-item-subtitle>{{ accountEmail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list v-if="isSignedIn" dense>
        <v-list-item-group active-class="primary--text">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-database-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Facilities Editor</v-list-item-title>
          </v-list-item>

          <v-list-item to="/suggest">
            <v-list-item-icon>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Facility Edit Suggestions</v-list-item-title>
          </v-list-item>

          <v-list-item to="/reviews">
            <v-list-item-icon>
              <v-icon>mdi-message-draw</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Facility Reviews</v-list-item-title>
          </v-list-item>

          <v-list-item to="/banners">
            <v-list-item-icon>
              <v-icon>mdi-wallpaper</v-icon>
            </v-list-item-icon>
            <v-list-item-title>In-App Banners</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Console Version</v-list-item-title>
            <v-list-item-subtitle>2022-05-06</v-list-item-subtitle>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    displayName: "bfGPS "
  }),
  mounted() {
    if (process.env.VUE_APP_DEV) {
      this.displayName = "bfGPS Dev ";
    }
  },
  computed: {
    isSignedIn() {
      return this.$store.getters.user.signedIn;
    },
    accountName() {
      if (this.$store.getters.user.data) {
        return this.$store.getters.user.data.displayName;
      } else {
        return "Not Signed In";
      }
    },
    accountEmail() {
      if (this.$store.getters.user.data) {
        return this.$store.getters.user.data.email;
      } else {
        return "";
      }
    },
  },
  methods: {
    onSignOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({ name: "Login" });
        });
    },
  },
};
</script>
