import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      signedIn: false,
      data: null,
      authToken: null
    }
  },
  getters: {
    user(state) {
      return state.user;
    }
  },
  mutations: {
    setUserSignedIn(state, value) {
      state.user.signedIn = value;
    },
    setUserData(state, data) {
      state.user.data = data;
    },
    setUserAuthToken(state, token) {
      state.user.authToken = token;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit('setUserSignedIn', (user !== null) && (user.role === 'admin'));
      if (user) {
        commit('setUserData', {
          displayName: user.displayName,
          email: user.email,
          role: user.role
        });
        commit('setUserAuthToken', user.authToken);
      } else {
        commit('setUserData', null);
      }
    }
  },
  modules: {
  }
});
