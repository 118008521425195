<template>
  <v-container fluid>
    <v-card class="ma-4">
      <v-card-title>
        <v-text-field
          v-model="tableSearch"
          prepend-icon="mdi-magnify"
          label="Filter facility reviews"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <vue-json-to-csv
          :json-data="reviews"
          :csv-title="generateCSVFilename()"
        >
          <v-btn elevation="0">
            <v-icon left>mdi-export-variant</v-icon>
            Export CSV
          </v-btn>
        </vue-json-to-csv>
      </v-card-title>
      <v-data-table
        :headers="tableHeaders"
        :items="reviews"
        :search="tableSearch"
        :sort-by="['date']"
        :sort-desc="[true]"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "FacilityReviews",
  components: { VueJsonToCsv },
  data: () => ({
    tableSearch: "",
    tableHeaders: [
      { text: "Facility ID", value: "facility_id" },
      { text: "Site", value: "site" },
      { text: "Floor", value: "floor", sortable: false },
      { text: "Location", value: "location", sortable: false },
      { text: "Review Date", value: "date" },
      { text: "User ID", value: "user" },
      { text: "Equipment Rating", value: "ratings_equipment" },
      { text: "Cleanliness Rating", value: "ratings_cleanliness" },
      { text: "Comfort Rating", value: "ratings_comfort" },
    ],
    reviews: [],
  }),
  mounted() {
    this.fetchReviews();
  },
  watch: {},
  methods: {
    formatDateTime(date) {
      return moment(date).tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm:ss");
    },
    generateCSVFilename() {
      let timestamp = moment().format("YYYYMMDDHHmmss");
      return `bfGPS_Facility_Reviews_${timestamp}`;
    },
    fetchReviews() {
      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .get(`${process.env.VUE_APP_API_HOST}/comments`, config)
        .then((response) => {
          this.reviews = [];
          let data = response.data.comments;
          for (let r of data) {
            if (r["facility"] !== null) {
              this.reviews.push({
                facility_id: r["facility"]["_id"],
                site: r["facility"]["site"]["name"]["zh_HK"],
                floor: r["facility"]["floor"]["name"],
                location: r["facility"]["location"]["zh_HK"],
                date: this.formatDateTime(r["date"]),
                user: r["user"],
                ratings_equipment: r["ratings"]["equipment"],
                ratings_cleanliness: r["ratings"]["cleanliness"],
                ratings_comfort: r["ratings"]["comfort"],
              });
            }
          }
        });
    },
  },
};
</script>
