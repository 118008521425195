<template>
  <LoginForm v-on:sign-in="signIn" v-bind:error="errorMessage"></LoginForm>
</template>

<script>
import firebase from "firebase/app";

import LoginForm from "@/components/LoginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  data: () => ({
    errorMessage: "",
  }),
  methods: {
    signIn(user) {
      if (user) {
        firebase
          .auth()
          .signInWithEmailAndPassword(user.email, user.password)
          .then((data) => {
            firebase
              .auth()
              .currentUser.getIdTokenResult(true)
              .then((result) => {
                let user = data.user;
                user.role = result.claims.role;
                if (user.role === "admin") {
                  user.authToken = result.token;
                  this.$store.dispatch("fetchUser", user);
                  this.$router.replace({ name: "Home" });
                } else {
                  this.errorMessage =
                    "Access denied. You are not authorized to access the Management Console.";
                  firebase.auth().signOut();
                }
              });
          })
          .catch((error) => {
            this.errorMessage = error.message;
          });
      }
    },
  },
};
</script>
