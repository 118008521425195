<template>
  <v-container fluid>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>

    <v-row>
      <v-col cols="4">
        <v-card elevation="0">
          <v-card-text class="pa-0">
            <facility-edit-suggestions-list
              v-bind:suggestions="suggestions"
              v-on:select="onSuggestionSelected"
            ></facility-edit-suggestions-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card
          v-if="!selected"
          elevation="2"
          class="content-pane ma-4 overflow-y-auto"
        >
          <v-card-text class="text-center text-h5">
            <v-container fill-height fluid style="height: 80vh">
              <v-row align="center" justify="center">
                <div>
                  <p>Select an edit suggestion to inspect</p>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <facility-edit-suggestion-inspector
          v-else
          v-bind:facility="existingFacility"
          v-bind:suggestion="selected"
          v-on:approve="onSuggestionApproved"
          v-on:reject="onSuggestionRejected"
          class="content-pane ma-4 overflow-y-auto"
        ></facility-edit-suggestion-inspector>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

import FacilityEditSuggestionsList from "@/components/FacilityEditSuggestionsList";
import FacilityEditSuggestionInspector from "@/components/FacilityEditSuggestionInspector";

export default {
  name: "FacilityEditSuggestions",
  components: {
    FacilityEditSuggestionsList,
    FacilityEditSuggestionInspector,
  },
  data: () => ({
    suggestions: [],
    selectedRef: "",
    selected: null,
    existingFacility: null,
    create: false,
    snackbar: {
      show: false,
      color: "success",
      message: "",
    },
  }),
  mounted() {
    this.fetchEditSuggestions();
  },
  methods: {
    fetchEditSuggestions() {
      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .get(`${process.env.VUE_APP_API_HOST}/facilities/suggest`, config)
        .then((response) => {
          this.suggestions = response.data.suggestions;
        });
    },
    onSuggestionSelected(suggestion) {
      if (suggestion.ref === this.selectedRef) {
        // Debouncing
        return;
      }

      if (!suggestion) {
        this.selected = null;
        return;
      }

      if (suggestion.facility._id) {
        // Existing facility

        const token = this.$store.getters.user.authToken;
        let config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .get(
            `${process.env.VUE_APP_API_HOST}/facilities/${suggestion.facility._id}/history`,
            config
          )
          .then((response) => {
            this.existingFacility = response.data.facility;
            this.selected = suggestion;
          });
      } else {
        // New facility
        this.existingFacility = null;
        this.selected = suggestion;
      }
      this.selectedRef = suggestion.ref;
    },
    async onSuggestionApproved(facilityJson) {
      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const facility = JSON.parse(facilityJson);
      if (this.selected.facility._id) {
        // Updates existing facility
        await axios.post(
          `${process.env.VUE_APP_API_HOST}/facilities/${facility._id}`,
          facility,
          config
        );
      } else {
        // Creates a new facility
        await axios.put(
          `${process.env.VUE_APP_API_HOST}/facilities`,
          facility,
          config
        );
      }

      axios
        .get(
          `${process.env.VUE_APP_API_HOST}/facilities/suggest/${this.selected._id}/approve`,
          config
        )
        .then((response) => {
          this.selected = response.data.suggestion;
          this.fetchEditSuggestions();

          this.snackbar.color = "success";
          this.snackbar.message = "Facility edit suggestion approved.";
          this.snackbar.show = true;
        })
        .catch((error) => {
          this.snackbar.color = "error";
          this.snackbar.message = error.message;
          this.snackbar.show = true;
        });
    },
    onSuggestionRejected(suggestionId) {
      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .get(
          `${process.env.VUE_APP_API_HOST}/facilities/suggest/${suggestionId}/reject`,
          config
        )
        .then((response) => {
          this.selected = response.data.suggestion;
          this.fetchEditSuggestions();
          this.snackbar.color = "success";
          this.snackbar.message = "Facility edit suggestion rejected.";
          this.snackbar.show = true;
        })
        .catch((error) => {
          this.snackbar.color = "error";
          this.snackbar.message = error.message;
          this.snackbar.show = true;
        });
    },
  },
};
</script>

<style scoped>
.content-pane {
  height: 85vh;
}
</style>
