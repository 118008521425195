<template>
  <v-card elevation="2">
    <v-card-title class="text-uppercase mb-2">
      <span class="text--disabled">
        <h4 v-if="!facility">Add a new facility</h4>
        <h4 v-else>Update an existing facility</h4>
      </span>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        elevation="0"
        v-on:click="onSubmit"
        :disabled="!valid"
      >
        <v-icon left>mdi-content-save</v-icon>
        Save &#38; Release
      </v-btn>
      <v-btn
        v-if="facility"
        class="ml-4"
        color="error"
        elevation="0"
        v-on:click="onRevertChanges"
      >
        <v-icon left>mdi-cancel</v-icon>
        Revert Changes
      </v-btn>
      <v-btn
        icon
        v-if="facility"
        class="ml-4"
        color="error"
        elevation="0"
        v-on:click="showDeleteFacilityConfirmation = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-title>

    <!-- Delete Facility Confirmation -->
    <v-dialog v-model="showDeleteFacilityConfirmation" max-width="480">
      <v-card>
        <v-card-title>Delete Facility</v-card-title>
        <v-card-text>
          The facility details cannot be recovered after deletion. Please
          proceed with caution.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" v-on:click="deleteFacility"> Delete </v-btn>
          <v-btn
            text
            color="accent"
            v-on:click="showDeleteFacilityConfirmation = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs v-model="tab" grow>
      <v-tab>Site Info</v-tab>
      <v-tab>Facility Info</v-tab>
      <v-tab>Images</v-tab>
      <v-tab>Opening Hours</v-tab>
      <v-tab>Equipment</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" v-if="model">
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Site Info -->
        <v-tab-item>
          <v-container class="pa-6" fill-height fluid>
            <!-- Site Unique ID (Read-only) -->
            <v-row v-if="model.site._id">
              <v-col cols="12">
                <v-text-field
                  v-model="model.site._id"
                  label="Site Unique ID"
                  :rules="[(v) => !!v || 'Required']"
                  required
                  readonly
                  disabled
                  outlined
                  :class="{
                    changed: facility && model.site._id !== original.site._id,
                  }"
                  :hint="
                    facility && model.site._id !== original.site._id
                      ? `Original: ${original.site._id}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Site Name -->
            <v-row class="pt-2">
              <v-col cols="6">
                <v-text-field
                  v-model="model.site.name.zh_HK"
                  label="Chinese Site Name"
                  :rules="[(v) => !!v || 'Required']"
                  required
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.site.name.zh_HK !== original.site.name.zh_HK,
                  }"
                  :hint="
                    facility &&
                    model.site.name.zh_HK !== original.site.name.zh_HK
                      ? `Original: ${original.site.name.zh_HK}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="model.site.name.en"
                  label="English Site Name"
                  :rules="[(v) => !!v || 'Required']"
                  required
                  outlined
                  :class="{
                    changed:
                      facility && model.site.name.en !== original.site.name.en,
                  }"
                  :hint="
                    facility && model.site.name.en !== original.site.name.en
                      ? `Original: ${original.site.name.en}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Site Address -->
            <v-row class="pt-2">
              <v-col cols="6">
                <v-text-field
                  v-model="model.site.address.zh_HK"
                  label="Chinese Site Address"
                  :rules="[(v) => !!v || 'Required']"
                  required
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.site.address.zh_HK !== original.site.address.zh_HK,
                  }"
                  :hint="
                    facility &&
                    model.site.address.zh_HK !== original.site.address.zh_HK
                      ? `Original: ${original.site.address.zh_HK}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="model.site.address.en"
                  label="English Site Address"
                  :rules="[(v) => !!v || 'Required']"
                  required
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.site.address.en !== original.site.address.en,
                  }"
                  :hint="
                    facility &&
                    model.site.address.en !== original.site.address.en
                      ? `Original: ${original.site.address.en}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- District and Coordinates -->
            <v-row class="pt-2">
              <v-col cols="6">
                <v-combobox
                  v-model="model.site.district"
                  :items="districts"
                  :item-text="displayBilingual"
                  label="District"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      typeof v === 'object' ||
                      'Select a district from the list',
                  ]"
                  return-object
                  outlined
                  :class="{
                    changed:
                      facility &&
                      JSON.stringify(model.site.district) !==
                        JSON.stringify(original.site.district),
                  }"
                  :hint="
                    facility &&
                    JSON.stringify(model.site.district) !==
                      JSON.stringify(original.site.district)
                      ? `Original: ${displayBilingual(original.site.district)}`
                      : ''
                  "
                  persistent-hint
                >
                </v-combobox>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="model.site.geo.coordinates[1]"
                  label="Latitude"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      /\d+\.\d+$/.test(v) ||
                      'Provide latitude in decimal format',
                  ]"
                  required
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.site.geo.coordinates[1] !=
                        original.site.geo.coordinates[1],
                  }"
                  :hint="
                    facility &&
                    model.site.geo.coordinates[1] !=
                      original.site.geo.coordinates[1]
                      ? `Original: ${original.site.geo.coordinates[1]}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="model.site.geo.coordinates[0]"
                  label="Longitude"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      /\d+\.\d+$/.test(v) ||
                      'Provide longitude in decimal format',
                  ]"
                  required
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.site.geo.coordinates[0] !=
                        original.site.geo.coordinates[0],
                  }"
                  :hint="
                    facility &&
                    model.site.geo.coordinates[0] !=
                      original.site.geo.coordinates[0]
                      ? `Original: ${original.site.geo.coordinates[0]}`
                      : ''
                  "
                  persistent-hint
                  append-outer-icon="mdi-map"
                  @click:append-outer="openInMap"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Type and BFCI Badge -->
            <v-row class="pt-2">
              <v-col cols="6">
                <v-select
                  v-model="model.site.type"
                  :items="siteTypes"
                  item-value="value"
                  item-text="text"
                  label="Site Type"
                  outlined
                  :class="{
                    changed: facility && model.site.type !== original.site.type,
                  }"
                  :hint="
                    facility && model.site.type !== original.site.type
                      ? `Original: ${original.site.type}`
                      : ''
                  "
                  persistent-hint
                >
                </v-select>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-switch
                  v-model="bfciBadge"
                  label="BFCI Badge"
                  inset
                ></v-switch>
              </v-col>
              <v-col cols="3">
                <v-menu
                  v-if="bfciBadge"
                  v-model="showBFCIBadgeDatePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="bfciBadgeDate"
                      label="Award Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="bfciBadgeDate"
                    @input="showBFCIBadgeDatePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- Phone and Website -->
            <v-row class="pt-2">
              <v-col cols="6">
                <v-text-field
                  v-model="model.site.phone"
                  label="Phone Number"
                  :rules="[
                    (v) =>
                      !!v ? /\d{8}$/.test(v) || 'Invalid phone number' : true,
                  ]"
                  outlined
                  :class="{
                    changed:
                      facility && model.site.phone !== original.site.phone,
                  }"
                  :hint="
                    facility && model.site.phone !== original.site.phone
                      ? `Original: ${original.site.phone}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="model.site.url"
                  label="Website URL"
                  :rules="[
                    (v) =>
                      !!v
                        ? /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(
                            v
                          ) || 'Invalid URL'
                        : true,
                  ]"
                  outlined
                  :class="{
                    changed: facility && model.site.url !== original.site.url,
                  }"
                  :hint="
                    facility && model.site.url !== original.site.url
                      ? `Original: ${original.site.url}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Facility Info -->
        <v-tab-item>
          <v-container class="pa-6" fill-height fluid>
            <!-- Facility Unique ID (Read-only) -->
            <v-row v-if="model._id">
              <v-col cols="12">
                <v-text-field
                  v-model="model._id"
                  label="Facility Unique ID"
                  :rules="[(v) => !!v || 'Required']"
                  required
                  readonly
                  disabled
                  outlined
                  :class="{
                    changed: facility && model._id !== original._id,
                  }"
                  :hint="
                    facility && model._id !== original._id
                      ? `Original: ${original._id}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Floor and Location -->
            <v-row class="pt-2">
              <v-col cols="2">
                <v-text-field
                  v-model="model.floor.name"
                  label="Floor Name"
                  outlined
                  :class="{
                    changed:
                      facility && model.floor.name !== original.floor.name,
                  }"
                  :hint="
                    facility && model.floor.name !== original.floor.name
                      ? `Original: ${original.floor.name}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="model.floor.code"
                  label="Floor Code"
                  outlined
                  :class="{
                    changed:
                      facility && model.floor.code !== original.floor.code,
                  }"
                  :hint="
                    facility && model.floor.code !== original.floor.code
                      ? `Original: ${original.floor.code}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="model.location.en"
                  label="English Location Name"
                  outlined
                  :class="{
                    changed:
                      facility && model.location.en !== original.location.en,
                  }"
                  :hint="
                    facility && model.location.en !== original.location.en
                      ? `Original: ${original.location.en}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="model.location.zh_HK"
                  label="Chinese Location Name"
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.location.zh_HK !== original.location.zh_HK,
                  }"
                  :hint="
                    facility && model.location.zh_HK !== original.location.zh_HK
                      ? `Original: ${original.location.zh_HK}`
                      : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Accessibility -->
            <v-row class="pt-2">
              <v-subheader class="text-uppercase">Accessibility</v-subheader>
            </v-row>
            <v-row>
              <!-- Public Access -->
              <v-col cols="4" class="px-6">
                <v-switch
                  v-model="model.public_access"
                  :label="
                    model.public_access
                      ? 'Open to public'
                      : 'Contact staff for access'
                  "
                  inset
                  :class="{
                    changed:
                      facility &&
                      model.public_access !== original.public_access,
                  }"
                  :hint="
                    facility && model.public_access !== original.public_access
                      ? `Toggled from original value`
                      : ''
                  "
                  persistent-hint
                ></v-switch>
              </v-col>
              <v-col cols="4" class="px-6">
                <v-switch
                  v-model="model.male_allowed"
                  :true-value="false"
                  :false-value="true"
                  label="Female only"
                  inset
                  :class="{
                    changed:
                      facility && model.male_allowed !== original.male_allowed,
                  }"
                  :hint="
                    facility && model.male_allowed !== original.male_allowed
                      ? `Toggled from original value`
                      : ''
                  "
                  persistent-hint
                ></v-switch>
              </v-col>
            </v-row>

            <!-- Remarks -->
            <v-row class="mt-2 pt-2">
              <v-col cols="6">
                <v-textarea
                  v-model="model.remarks.zh_HK"
                  label="Chinese Facility Remarks"
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.remarks.zh_HK !== original.remarks.zh_HK,
                  }"
                  :hint="
                    facility && model.remarks.zh_HK !== original.remarks.zh_HK
                      ? `Original: ${original.remarks.zh_HK}`
                      : ''
                  "
                  persistent-hint
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="model.remarks.en"
                  label="English Facility Remarks"
                  outlined
                  :class="{
                    changed:
                      facility && model.remarks.en !== original.remarks.en,
                  }"
                  :hint="
                    facility && model.remarks.en !== original.remarks.en
                      ? `Original: ${original.remarks.en}`
                      : ''
                  "
                  persistent-hint
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Images -->
        <v-tab-item>
          <v-container class="pa-6" fill-height fluid>
            <v-row>
              <v-col
                cols="4"
                class="d-flex child-flex"
                v-for="i in model.images"
                :key="i._id"
              >
                <v-card min-height="300">
                  <v-img
                    :src="getImagePath(i.path)"
                    class="grey darken-4"
                    height="192"
                    contain
                  >
                  </v-img>
                  <v-card-subtitle>
                    <v-icon small class="mr-2">mdi-account-edit</v-icon>
                    <span v-if="!i.author.en">(Unspecified)</span>
                    <span v-else>{{ displayBilingual(i.author) }}</span>
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-btn
                      icon
                      :disabled="model.images.indexOf(i) === 0"
                      v-on:click="shiftImageLeft(i)"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      :disabled="
                        model.images.indexOf(i) === model.images.length - 1
                      "
                      v-on:click="shiftImageRight(i)"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon :href="getImagePath(i.path)" target="_blank">
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                    <v-btn icon v-on:click="editImageAuthor(i)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="red" v-on:click="deleteImage(i)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="4" class="d-flex child-flex">
                <v-card min-height="300">
                  <v-container fluid fill-height>
                    <v-row align="center" justify="center">
                      <v-btn
                        text
                        color="primary"
                        :loading="
                          uploadImage.selecting || uploadImage.uploading
                        "
                        v-on:click="selectUploadImage"
                      >
                        <v-icon left>mdi-upload</v-icon>
                        UPLOAD IMAGE
                      </v-btn>
                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="image/jpeg, image/png"
                        v-on:change="onFileChanged"
                      />
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Image Author Editor -->
        <v-dialog v-model="showImageAuthorEditor" max-width="480">
          <v-card>
            <v-card-title>Edit Image Author</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-text-field
                    v-model="imageAuthorModel.image.author.zh_HK"
                    label="Chinese Author Name"
                    outlined
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="imageAuthorModel.image.author.en"
                    label="English Author Name"
                    outlined
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-switch
                    v-model="imageAuthorModel.updateAll"
                    label="Apply to all images"
                    inset
                  ></v-switch>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="accent" v-on:click="updateImageAuthor">
                Update
              </v-btn>
              <v-btn
                text
                color="accent"
                v-on:click="showImageAuthorEditor = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Opening Hours -->
        <v-tab-item>
          <v-container class="pa-6" fill-height fluid>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="openingHoursTableHeaders"
                  :items="openingHours"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:item.weekday="{ item }">
                    <v-checkbox v-model="item.weekday"></v-checkbox>
                  </template>
                  <template v-slot:item.sat="{ item }">
                    <v-checkbox v-model="item.sat"></v-checkbox>
                  </template>
                  <template v-slot:item.sun="{ item }">
                    <v-checkbox v-model="item.sun"></v-checkbox>
                  </template>
                  <template v-slot:item.ph="{ item }">
                    <v-checkbox v-model="item.ph"></v-checkbox>
                  </template>

                  <template v-slot:item.start="{ item }">
                    <v-edit-dialog :return-value.sync="item.start">
                      {{ item.start ? item.start : "(Not set)" }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.start"
                          :rules="[
                            (v) => !!v || 'Required',
                            (v) =>
                              /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) ||
                              'Invalid 24-hour HH:MM format',
                            (v) =>
                              v.localeCompare(item.end) < 0 ||
                              'Must be earlier than session end time',
                          ]"
                          hint="Press Enter to save"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.end="{ item }">
                    <v-edit-dialog :return-value.sync="item.end">
                      {{ item.end ? item.end : "(Not set)" }}
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.end"
                          :rules="[
                            (v) => !!v || 'Required',
                            (v) =>
                              /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) ||
                              'Invalid 24-hour HH:MM format',
                            (v) =>
                              v.localeCompare(item.start) > 0 ||
                              'Must be later than session start time',
                          ]"
                          hint="Press Enter to save"
                          single-line
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:item.action="{ item }">
                    <v-btn icon v-on:click="deleteOpeningHourSession(item)">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col cols="12">
                <v-btn text v-on:click="addOpeningHourSession">
                  <v-icon left>mdi-plus</v-icon>
                  Add session
                </v-btn>
              </v-col>
            </v-row>

            <!-- Remarks -->
            <v-row class="mt-2">
              <v-col cols="6">
                <v-textarea
                  v-model="model.opening_hours.remarks.zh_HK"
                  label="Chinese Opening Hours Remarks"
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.opening_hours.remarks.zh_HK !==
                        original.opening_hours.remarks.zh_HK,
                  }"
                  :hint="
                    facility &&
                    model.opening_hours.remarks.zh_HK !==
                      original.opening_hours.remarks.zh_HK
                      ? `Original: ${original.opening_hours.remarks.zh_HK}`
                      : ''
                  "
                  persistent-hint
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="model.opening_hours.remarks.en"
                  label="English Opening Hours Remarks"
                  outlined
                  :class="{
                    changed:
                      facility &&
                      model.opening_hours.remarks.en !==
                        original.opening_hours.remarks.en,
                  }"
                  :hint="
                    facility &&
                    model.opening_hours.remarks.en !==
                      original.opening_hours.remarks.en
                      ? `Original: ${original.opening_hours.remarks.en}`
                      : ''
                  "
                  persistent-hint
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- Equipment -->
        <v-tab-item>
          <v-container class="pa-6" fill-height fluid>
            <v-col cols="12">
              <v-row>
                <v-switch
                  v-model="noEquipmentData"
                  label="Hide equipment fields (applicable to facilities without such information)"
                  inset
                ></v-switch>
              </v-row>

              <div v-if="!noEquipmentData">
                <!-- Basic equipment -->
                <v-row class="mt-4 mb-4">
                  <v-checkbox
                    v-model="model.equipment.lock"
                    label="Door can be locked"
                    :class="{
                      changed:
                        facility &&
                        model.equipment.lock !== original.equipment.lock,
                    }"
                    :hint="
                      facility &&
                      model.equipment.lock !== original.equipment.lock
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-slider
                    v-model="model.equipment.feeding_rooms"
                    label="Private feeding room"
                    min="0"
                    max="10"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="model.equipment.feeding_rooms"
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                        :class="{
                          changed:
                            facility &&
                            model.equipment.feeding_rooms !==
                              original.equipment.feeding_rooms,
                        }"
                        :hint="
                          facility &&
                          model.equipment.feeding_rooms !==
                            original.equipment.feeding_rooms
                            ? `Original: ${original.equipment.feeding_rooms}`
                            : ''
                        "
                        persistent-hint
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-row>
                <v-row class="pt-2">
                  <v-slider
                    v-model="model.equipment.changing_mats.total"
                    label="Diaper changing mat"
                    min="0"
                    max="10"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="model.equipment.changing_mats.total"
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                        :class="{
                          changed:
                            facility &&
                            model.equipment.changing_mats.total !==
                              original.equipment.changing_mats.total,
                        }"
                        :hint="
                          facility &&
                          model.equipment.changing_mats.total !==
                            original.equipment.changing_mats.total
                            ? `Original: ${original.equipment.changing_mats.total}`
                            : ''
                        "
                        persistent-hint
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.equipment.changing_mats.cushioned"
                    label="Cushioned changing mat(s)"
                    :class="{
                      changed:
                        facility &&
                        model.equipment.changing_mats.cushioned !==
                          original.equipment.changing_mats.cushioned,
                    }"
                    :hint="
                      facility &&
                      model.equipment.changing_mats.cushioned !==
                        original.equipment.changing_mats.cushioned
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  ></v-checkbox>
                </v-row>
                <v-row class="mt-4 pt-2">
                  <v-slider
                    v-model="model.equipment.hand_washing"
                    label="Handwashing facility"
                    min="0"
                    max="10"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="model.equipment.hand_washing"
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                        :class="{
                          changed:
                            facility &&
                            model.equipment.hand_washing !==
                              original.equipment.hand_washing,
                        }"
                        :hint="
                          facility &&
                          model.equipment.hand_washing !==
                            original.equipment.hand_washing
                            ? `Original: ${original.equipment.hand_washing}`
                            : ''
                        "
                        persistent-hint
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-row>
                <v-row class="pt-2">
                  <v-slider
                    v-model="model.equipment.power_sockets"
                    label="Power socket"
                    min="0"
                    max="10"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="model.equipment.power_sockets"
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                        :class="{
                          changed:
                            facility &&
                            model.equipment.power_sockets !==
                              original.equipment.power_sockets,
                        }"
                        :hint="
                          facility &&
                          model.equipment.power_sockets !==
                            original.equipment.power_sockets
                            ? `Original: ${original.equipment.power_sockets}`
                            : ''
                        "
                        persistent-hint
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-row>
                <v-row class="pt-2">
                  <v-slider
                    v-model="model.equipment.bottle_warmers.total"
                    label="Bottle warmer"
                    min="0"
                    max="10"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="model.equipment.bottle_warmers.total"
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                        :class="{
                          changed:
                            facility &&
                            model.equipment.bottle_warmers.total !==
                              original.equipment.bottle_warmers.total,
                        }"
                        :hint="
                          facility &&
                          model.equipment.bottle_warmers.total !==
                            original.equipment.bottle_warmers.total
                            ? `Original: ${original.equipment.bottle_warmers.total}`
                            : ''
                        "
                        persistent-hint
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-row>
                <v-row class="pt-2">
                  <v-select
                    v-model="model.equipment.bottle_warmers.availability"
                    label="Bottle warmer availability"
                    :items="[
                      { text: 'Not available', value: 'N/A' },
                      { text: 'Inside the facility', value: 'ROOM' },
                      { text: 'Ask staff', value: 'STAFF' },
                    ]"
                    :class="{
                      changed:
                        facility &&
                        model.equipment.bottle_warmers.availability !==
                          original.equipment.bottle_warmers.availability,
                    }"
                    :hint="
                      facility &&
                      model.equipment.bottle_warmers.availability !==
                        original.equipment.bottle_warmers.availability
                        ? `Original: ${original.equipment.bottle_warmers.availability}`
                        : ''
                    "
                    persistent-hint
                  ></v-select>
                </v-row>
                <v-row class="mt-4 pt-2">
                  <v-slider
                    v-model="model.equipment.seats"
                    label="Seat"
                    min="0"
                    max="10"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="model.equipment.seats"
                        class="mt-0 pt-0"
                        type="number"
                        style="width: 60px"
                        :class="{
                          changed:
                            facility &&
                            model.equipment.seats !== original.equipment.seats,
                        }"
                        :hint="
                          facility &&
                          model.equipment.seats !== original.equipment.seats
                            ? `Original: ${original.equipment.seats}`
                            : ''
                        "
                        persistent-hint
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-row>
                <v-row class="mt-2 pt-2">
                  <v-col cols="6">
                    <v-textarea
                      v-model="model.equipment.remarks.zh_HK"
                      label="Chinese General Equipment Remarks"
                      outlined
                      :class="{
                        changed:
                          facility &&
                          model.equipment.remarks.zh_HK !==
                            original.equipment.remarks.zh_HK,
                      }"
                      :hint="
                        facility &&
                        model.equipment.remarks.zh_HK !==
                          original.equipment.remarks.zh_HK
                          ? `Original: ${original.equipment.remarks.zh_HK}`
                          : ''
                      "
                      persistent-hint
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      v-model="model.equipment.remarks.en"
                      label="English General Equipment Remarks"
                      outlined
                      :class="{
                        changed:
                          facility &&
                          model.equipment.remarks.en !==
                            original.equipment.remarks.en,
                      }"
                      :hint="
                        facility &&
                        model.equipment.remarks.en !==
                          original.equipment.remarks.en
                          ? `Original: ${original.equipment.remarks.en}`
                          : ''
                      "
                      persistent-hint
                    ></v-textarea>
                  </v-col>
                </v-row>

                <!-- Extra Equipment -->
                <v-row class="mt-2">
                  <v-subheader class="text-uppercase">
                    Extra Equipment
                  </v-subheader>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-model="model.extra.air_purifier"
                    label="Air purifier"
                    :class="{
                      changed:
                        facility &&
                        model.extra.air_purifier !==
                          original.extra.air_purifier,
                    }"
                    :hint="
                      facility &&
                      model.extra.air_purifier !== original.extra.air_purifier
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.extra.electronic_scale"
                    label="Electronic scale"
                    :class="{
                      changed:
                        facility &&
                        model.extra.electronic_scale !==
                          original.extra.electronic_scale,
                    }"
                    :hint="
                      facility &&
                      model.extra.electronic_scale !==
                        original.extra.electronic_scale
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.extra.baby_wipe"
                    label="Baby wipe"
                    :class="{
                      changed:
                        facility &&
                        model.extra.baby_wipe !== original.extra.baby_wipe,
                    }"
                    :hint="
                      facility &&
                      model.extra.baby_wipe !== original.extra.baby_wipe
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.extra.baby_lotion"
                    label="Baby lotion"
                    :class="{
                      changed:
                        facility &&
                        model.extra.baby_lotion !== original.extra.baby_lotion,
                    }"
                    :hint="
                      facility &&
                      model.extra.baby_lotion !== original.extra.baby_lotion
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.extra.baby_powder"
                    label="Baby powder"
                    :class="{
                      changed:
                        facility &&
                        model.extra.baby_powder !== original.extra.baby_powder,
                    }"
                    :hint="
                      facility &&
                      model.extra.baby_powder !== original.extra.baby_powder
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.extra.drinking_water.cold"
                    label="Cold drinking water"
                    :class="{
                      changed:
                        facility &&
                        model.extra.drinking_water.cold !==
                          original.extra.drinking_water.cold,
                    }"
                    :hint="
                      facility &&
                      model.extra.drinking_water.cold !==
                        original.extra.drinking_water.cold
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.extra.drinking_water.hot"
                    label="Hot drinking water"
                    :class="{
                      changed:
                        facility &&
                        model.extra.drinking_water.hot !==
                          original.extra.drinking_water.hot,
                    }"
                    :hint="
                      facility &&
                      model.extra.drinking_water.hot !==
                        original.extra.drinking_water.hot
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="mt-2 pt-2">
                  <v-col cols="6">
                    <v-textarea
                      v-model="model.extra.remarks.zh_HK"
                      label="Chinese Extra Equipment Remarks"
                      outlined
                      :class="{
                        changed:
                          facility &&
                          model.extra.remarks.zh_HK !==
                            original.extra.remarks.zh_HK,
                      }"
                      :hint="
                        facility &&
                        model.extra.remarks.zh_HK !==
                          original.extra.remarks.zh_HK
                          ? `Original: ${original.extra.remarks.zh_HK}`
                          : ''
                      "
                      persistent-hint
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      v-model="model.extra.remarks.en"
                      label="English Extra Equipment Remarks"
                      outlined
                      :class="{
                        changed:
                          facility &&
                          model.extra.remarks.en !== original.extra.remarks.en,
                      }"
                      :hint="
                        facility &&
                        model.extra.remarks.en !== original.extra.remarks.en
                          ? `Original: ${original.extra.remarks.en}`
                          : ''
                      "
                      persistent-hint
                    ></v-textarea>
                  </v-col>
                </v-row>

                <!-- Additional Services -->
                <v-row class="mt-2">
                  <v-subheader class="text-uppercase">
                    Additional Services
                  </v-subheader>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-model="model.services.milk_storage_bag"
                    label="Milk storage bag"
                    :class="{
                      changed:
                        facility &&
                        model.services.milk_storage_bag !==
                          original.services.milk_storage_bag,
                    }"
                    :hint="
                      facility &&
                      model.services.milk_storage_bag !==
                        original.services.milk_storage_bag
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.services.bottle_sterilizer"
                    label="Bottle sterilizer"
                    :class="{
                      changed:
                        facility &&
                        model.services.bottle_sterilizer !==
                          original.services.bottle_sterilizer,
                    }"
                    :hint="
                      facility &&
                      model.services.bottle_sterilizer !==
                        original.services.bottle_sterilizer
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.services.diaper"
                    label="Diaper"
                    :class="{
                      changed:
                        facility &&
                        model.services.diaper !== original.services.diaper,
                    }"
                    :hint="
                      facility &&
                      model.services.diaper !== original.services.diaper
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.services.disposable_bib"
                    label="Disposable bib"
                    :class="{
                      changed:
                        facility &&
                        model.services.disposable_bib !==
                          original.services.disposable_bib,
                    }"
                    :hint="
                      facility &&
                      model.services.disposable_bib !==
                        original.services.disposable_bib
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.services.thermometer"
                    label="Thermometer"
                    :class="{
                      changed:
                        facility &&
                        model.services.thermometer !==
                          original.services.thermometer,
                    }"
                    :hint="
                      facility &&
                      model.services.thermometer !==
                        original.services.thermometer
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.services.cooling_pack"
                    label="Cooling pack"
                    :class="{
                      changed:
                        facility &&
                        model.services.cooling_pack !==
                          original.services.cooling_pack,
                    }"
                    :hint="
                      facility &&
                      model.services.cooling_pack !==
                        original.services.cooling_pack
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.services.mask"
                    label="Face mask"
                    :class="{
                      changed:
                        facility &&
                        model.services.mask !== original.services.mask,
                    }"
                    :hint="
                      facility && model.services.mask !== original.services.mask
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.services.stroller"
                    label="Stroller"
                    :class="{
                      changed:
                        facility &&
                        model.services.stroller !== original.services.stroller,
                    }"
                    :hint="
                      facility &&
                      model.services.stroller !== original.services.stroller
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="pt-2">
                  <v-checkbox
                    v-model="model.services.food_warming"
                    label="Food warming"
                    :class="{
                      changed:
                        facility &&
                        model.services.food_warming !==
                          original.services.food_warming,
                    }"
                    :hint="
                      facility &&
                      model.services.food_warming !==
                        original.services.food_warming
                        ? `Toggled from original value`
                        : ''
                    "
                    persistent-hint
                  >
                  </v-checkbox>
                </v-row>
                <v-row class="mt-2 pt-2">
                  <v-col cols="6">
                    <v-textarea
                      v-model="model.services.remarks.zh_HK"
                      label="Chinese Additional Services Remarks"
                      outlined
                      :class="{
                        changed:
                          facility &&
                          model.services.remarks.zh_HK !==
                            original.services.remarks.zh_HK,
                      }"
                      :hint="
                        facility &&
                        model.services.remarks.zh_HK !==
                          original.services.remarks.zh_HK
                          ? `Original: ${original.services.remarks.zh_HK}`
                          : ''
                      "
                      persistent-hint
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      v-model="model.services.remarks.en"
                      label="English Additional Services Remarks"
                      outlined
                      :class="{
                        changed:
                          facility &&
                          model.services.remarks.en !==
                            original.services.remarks.en,
                      }"
                      :hint="
                        facility &&
                        model.services.remarks.en !==
                          original.services.remarks.en
                          ? `Original: ${original.services.remarks.en}`
                          : ''
                      "
                      persistent-hint
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-container>
        </v-tab-item>
      </v-form>
    </v-tabs-items>
  </v-card>
</template>

<script>
import axios from "axios";
import { sify } from "chinese-conv";
import slugify from "slugify";

export default {
  name: "FacilityEditor",
  data: () => ({
    tab: null,
    valid: false,
    model: null,
    original: null,
    showBFCIBadgeDatePicker: false,
    bfciBadge: false,
    bfciBadgeDate: null,
    showDeleteFacilityConfirmation: false,
    showImageAuthorEditor: false,
    imageAuthorModel: {
      index: -1,
      image: {
        author: {
          en: "",
          zh_HK: "",
          zh_CN: "",
        },
      },
      updateAll: false,
    },
    uploadImage: {
      selecting: false,
      uploading: false,
      file: null,
    },
    imagesToDelete: [],
    openingHours: [],
    openingHoursTableHeaders: [
      { text: "Mon - Fri", value: "weekday" },
      { text: "Sat", value: "sat" },
      { text: "Sun", value: "sun" },
      { text: "Public holiday", value: "ph" },
      { text: "Session start", value: "start" },
      { text: "Session end", value: "end" },
      { text: "", value: "action" },
    ],
    noEquipmentData: false,
    siteTypes: [
      {
        value: "community",
        text: "Community facilities",
      },
      {
        value: "mall",
        text: "Shopping malls",
      },
      {
        value: "restaurant",
        text: "Restaurants",
      },
      {
        value: "transport",
        text: "Public transport facilities",
      },
      {
        value: "clinical",
        text: "Clinical facilities",
      },
      {
        value: "building",
        text: "Government and commercial buildings",
      },
    ],
    districts: [
      { en: "Ma Wan", zh_HK: "馬灣", zh_CN: "马湾" },
      { en: "Peng Chau", zh_HK: "坪洲", zh_CN: "坪洲" },
      { en: "Hei Ling Chau", zh_HK: "喜靈洲", zh_CN: "喜灵洲" },
      { en: "Sunshine Island", zh_HK: "周公島", zh_CN: "周公岛" },
      { en: "Cheung Chau", zh_HK: "長洲", zh_CN: "长洲" },
      { en: "Shek Kwu Chau", zh_HK: "石鼓洲", zh_CN: "石鼓洲" },
      { en: "Chek Lap Kok", zh_HK: "赤鱲角", zh_CN: "赤鱲角" },
      { en: "Lamma Island", zh_HK: "南丫島", zh_CN: "南丫岛" },
      { en: "Po Toi Island", zh_HK: "蒲台島", zh_CN: "蒲台岛" },
      { en: "Tung Lung Chau", zh_HK: "東龍洲", zh_CN: "东龙洲" },
      { en: "Lantau Island", zh_HK: "大嶼山", zh_CN: "大屿山" },
      { en: "West Kowloon", zh_HK: "西九龍", zh_CN: "西九龙" },
      { en: "Green Island", zh_HK: "青洲", zh_CN: "青洲" },
      { en: "Hung Hom", zh_HK: "紅磡", zh_CN: "红磡" },
      { en: "Stonecutters Island", zh_HK: "昂船洲", zh_CN: "昂船洲" },
      { en: "Tung Chung", zh_HK: "東涌", zh_CN: "东涌" },
      { en: "Mid-Levels", zh_HK: "半山區", zh_CN: "半山区" },
      { en: "Peak", zh_HK: "山頂", zh_CN: "山顶" },
      { en: "Kennedy Town", zh_HK: "堅尼地城", zh_CN: "坚尼地城" },
      { en: "Shek Tong Tsui", zh_HK: "石塘咀", zh_CN: "石塘咀" },
      { en: "Sai Ying Pun", zh_HK: "西營盤", zh_CN: "西营盘" },
      { en: "Sheung Wan", zh_HK: "上環", zh_CN: "上环" },
      { en: "Wan Chai", zh_HK: "灣仔", zh_CN: "湾仔" },
      { en: "Causeway Bay", zh_HK: "銅鑼灣", zh_CN: "铜锣湾" },
      { en: "Tin Hau", zh_HK: "天后", zh_CN: "天后" },
      { en: "Tai Hang", zh_HK: "大坑", zh_CN: "大坑" },
      { en: "Jardine's Lookout", zh_HK: "渣甸山", zh_CN: "渣甸山" },
      { en: "Happy Valley", zh_HK: "跑馬地", zh_CN: "跑马地" },
      { en: "Quarry Bay", zh_HK: "鰂魚涌", zh_CN: "鲗鱼涌" },
      { en: "Sai Wan Ho", zh_HK: "西灣河", zh_CN: "西湾河" },
      { en: "Shau Kei Wan", zh_HK: "筲箕灣", zh_CN: "筲箕湾" },
      { en: "Chai Wan", zh_HK: "柴灣", zh_CN: "柴湾" },
      { en: "Siu Sai Wan", zh_HK: "小西灣", zh_CN: "小西湾" },
      { en: "Braemar Hill", zh_HK: "寶馬山", zh_CN: "宝马山" },
      { en: "North Point", zh_HK: "北角", zh_CN: "北角" },
      { en: "Aberdeen", zh_HK: "香港仔", zh_CN: "香港仔" },
      { en: "Ap Lei Chau", zh_HK: "鴨脷洲", zh_CN: "鸭脷洲" },
      { en: "Pok Fu Lam", zh_HK: "薄扶林", zh_CN: "薄扶林" },
      { en: "Wong Chuk Hang", zh_HK: "黃竹坑", zh_CN: "黄竹坑" },
      { en: "Tsim Sha Tsui", zh_HK: "尖沙咀", zh_CN: "尖沙咀" },
      { en: "Jordan", zh_HK: "佐敦", zh_CN: "佐敦" },
      { en: "Yau Ma Tei", zh_HK: "油麻地", zh_CN: "油麻地" },
      { en: "Tai Kok Tsui", zh_HK: "大角咀", zh_CN: "大角咀" },
      { en: "Mong Kok", zh_HK: "旺角", zh_CN: "旺角" },
      { en: "Cheung Sha Wan", zh_HK: "長沙灣", zh_CN: "长沙湾" },
      { en: "Sham Shui Po", zh_HK: "深水埗", zh_CN: "深水埗" },
      { en: "Shek Kip Mei", zh_HK: "石硤尾", zh_CN: "石硖尾" },
      { en: "Lai Chi Kok", zh_HK: "荔枝角", zh_CN: "荔枝角" },
      { en: "Kowloon Tong", zh_HK: "九龍塘", zh_CN: "九龙塘" },
      { en: "Ma Tau Wai", zh_HK: "馬頭圍", zh_CN: "马头围" },
      { en: "Ho Man Tin", zh_HK: "何文田", zh_CN: "何文田" },
      { en: "Kowloon City", zh_HK: "九龍城", zh_CN: "九龙城" },
      { en: "Kai Tak", zh_HK: "啟德", zh_CN: "启德" },
      { en: "To Kwa Wan", zh_HK: "土瓜灣", zh_CN: "土瓜湾" },
      { en: "Wong Tai Sin", zh_HK: "黃大仙", zh_CN: "黄大仙" },
      { en: "Diamond Hill", zh_HK: "鑽石山", zh_CN: "钻石山" },
      { en: "San Po Kong", zh_HK: "新蒲崗", zh_CN: "新蒲岗" },
      { en: "Lok Fu", zh_HK: "樂富", zh_CN: "乐富" },
      { en: "Tsz Wan Shan", zh_HK: "慈雲山", zh_CN: "慈云山" },
      { en: "Ngau Chi Wan", zh_HK: "牛池灣", zh_CN: "牛池湾" },
      { en: "Kowloon Bay", zh_HK: "九龍灣", zh_CN: "九龙湾" },
      { en: "Jordan Valley", zh_HK: "佐敦谷", zh_CN: "佐敦谷" },
      { en: "Kwun Tong", zh_HK: "觀塘", zh_CN: "观塘" },
      { en: "Sau Mau Ping", zh_HK: "秀茂坪", zh_CN: "秀茂坪" },
      { en: "Lam Tin", zh_HK: "藍田", zh_CN: "蓝田" },
      { en: "Yau Tong", zh_HK: "油塘", zh_CN: "油塘" },
      { en: "Lei Yue Mun", zh_HK: "鯉魚門", zh_CN: "鲤鱼门" },
      { en: "Ngau Tau Kok", zh_HK: "牛頭角", zh_CN: "牛头角" },
      { en: "Tsuen Wan", zh_HK: "荃灣", zh_CN: "荃湾" },
      { en: "Tsing Lung Tau", zh_HK: "青龍頭", zh_CN: "青龙头" },
      { en: "Sham Tseng", zh_HK: "深井", zh_CN: "深井" },
      { en: "Tuen Mun", zh_HK: "屯門", zh_CN: "屯门" },
      { en: "So Kwun Wat", zh_HK: "掃管笏", zh_CN: "扫管笏" },
      { en: "Yuen Long", zh_HK: "元朗", zh_CN: "元朗" },
      { en: "Tin Shui Wai", zh_HK: "天水圍", zh_CN: "天水围" },
      { en: "Kam Tin", zh_HK: "錦田", zh_CN: "锦田" },
      { en: "Pat Heung", zh_HK: "八鄉", zh_CN: "八乡" },
      { en: "Fanling", zh_HK: "粉嶺", zh_CN: "粉岭" },
      { en: "Sheung Shui", zh_HK: "上水", zh_CN: "上水" },
      { en: "Tai Po", zh_HK: "大埔", zh_CN: "大埔" },
      { en: "Sai Kung", zh_HK: "西貢", zh_CN: "西贡" },
      { en: "Clear Water Bay", zh_HK: "清水灣", zh_CN: "清水湾" },
      { en: "Tseung Kwan O", zh_HK: "將軍澳", zh_CN: "将军澳" },
      { en: "Tiu Keng Leng", zh_HK: "調景嶺", zh_CN: "调景岭" },
      { en: "Hang Hau", zh_HK: "坑口", zh_CN: "坑口" },
      { en: "Sha Tin", zh_HK: "沙田", zh_CN: "沙田" },
      { en: "Tai Wai", zh_HK: "大圍", zh_CN: "大围" },
      { en: "Fo Tan", zh_HK: "火炭", zh_CN: "火炭" },
      { en: "Ma On Shan", zh_HK: "馬鞍山", zh_CN: "马鞍山" },
      { en: "Kwai Chung", zh_HK: "葵涌", zh_CN: "葵涌" },
      { en: "Tsing Yi", zh_HK: "青衣", zh_CN: "青衣" },
      { en: "Tai Tam", zh_HK: "大潭", zh_CN: "大潭" },
      { en: "Lai King", zh_HK: "荔景", zh_CN: "荔景" },
      { en: "Central", zh_HK: "中環", zh_CN: "中环" },
      { en: "Shek Pai Wan", zh_HK: "石排灣", zh_CN: "石排湾" },
      { en: "Admiralty", zh_HK: "金鐘", zh_CN: "金钟" },
      { en: "Lei Tung", zh_HK: "利東", zh_CN: "利东" },
      { en: "Tin Wan", zh_HK: "田灣", zh_CN: "田湾" },
      { en: "Choi Hung", zh_HK: "彩虹", zh_CN: "彩虹" },
      { en: "Stanley", zh_HK: "赤柱", zh_CN: "赤柱" },
      { en: "Kwai Shing", zh_HK: "葵盛", zh_CN: "葵盛" },
      { en: "Tung Tau", zh_HK: "東頭", zh_CN: "东头" },
      { en: "Wang Tau Hom", zh_HK: "橫頭磡", zh_CN: "横头磡" },
      { en: "Lai Yiu", zh_HK: "麗瑤", zh_CN: "丽瑶" },
      { en: "Kwai Fong", zh_HK: "葵芳", zh_CN: "葵芳" },
      { en: "Kwai Hing", zh_HK: "葵興", zh_CN: "葵兴" },
      { en: "Tai Wo Hau", zh_HK: "大窩口", zh_CN: "大窝口" },
      { en: "Lei Muk Shue", zh_HK: "梨木樹", zh_CN: "梨木树" },
      { en: "Repulse Bay", zh_HK: "淺水灣", zh_CN: "浅水湾" },
      { en: "Sha Tau Kok", zh_HK: "沙頭角", zh_CN: "沙头角" },
      { en: "Chung Hom Kok", zh_HK: "舂磡角", zh_CN: "舂磡角" },
      { en: "Shouson Hill", zh_HK: "壽臣山", zh_CN: "寿臣山" },
      { en: "Deep Water Bay", zh_HK: "深水灣", zh_CN: "深水湾" },
      { en: "Shek O", zh_HK: "石澳", zh_CN: "石澳" },
    ],
    openingHoursDays: ["weekday", "sat", "sun", "ph"],
  }),
  props: {
    facility: Object,
  },
  mounted() {
    this.copyFacilityToWorkspace(this.facility);
  },
  watch: {
    facility(f) {
      this.copyFacilityToWorkspace(f);
    },
  },
  methods: {
    copyFacilityToWorkspace(facility) {
      if (facility) {
        this.model = JSON.parse(JSON.stringify(this.facility));

        // Parses BFCI Badge status
        if (this.model.site.badge && this.model.site.badge.class === "basic") {
          this.bfciBadge = true;
          this.bfciBadgeDate = new Date(this.model.site.badge.updated)
            .toISOString()
            .split("T")[0];
        } else {
          this.bfciBadge = false;
          this.bfciBadgeDate = new Date().toISOString().split("T")[0];
        }

        // Handles no equipment special cases
        if (!facility.equipment) {
          this.noEquipmentData = true;
          this.model.equipment = {
            changing_mats: { total: 0, cushioned: false },
            bottle_warmers: { availability: "N/A", total: 0 },
            lock: false,
            feeding_rooms: 0,
            hand_washing: 0,
            power_sockets: 0,
            seats: 0,
            remarks: { en: "", zh_HK: "", zh_CN: "" },
          };
          this.model.extra = {
            drinking_water: { cold: false, hot: false },
            air_purifier: false,
            electronic_scale: false,
            baby_wipe: false,
            baby_lotion: false,
            baby_powder: false,
            remarks: { en: "", zh_HK: "", zh_CN: "" },
          };
          this.model.services = {
            feeding_bib: false,
            milk_storage_bag: false,
            bottle_sterilizer: false,
            diaper: false,
            disposable_bib: false,
            thermometer: false,
            cooling_pack: false,
            mask: false,
            stroller: false,
            food_warming: false,
            remarks: { en: "", zh_HK: "", zh_CN: "" },
          };
        } else {
          this.noEquipmentData = false;
        }

        // Parses opening hours data structure
        this.openingHours = [];
        let sessions = [];
        for (let day in this.model.opening_hours) {
          if (day === "remarks") {
            continue;
          }
          for (let s of this.model.opening_hours[day]) {
            const start = s[0].toString().padStart(4, "0");
            const end = s[1].toString().padStart(4, "0");
            let label = `${start}-${end}`;
            if (sessions.includes(label)) {
              const index = sessions.indexOf(label);
              this.openingHours[index][day] = true;
            } else {
              sessions.push(label);
              this.openingHours.push({
                weekday: day === "weekday",
                sat: day === "sat",
                sun: day === "sun",
                ph: day === "ph",
                start: `${start.substring(0, 2)}:${start.substring(2, 4)}`,
                end: `${end.substring(0, 2)}:${end.substring(2, 4)}`,
              });
            }
          }
        }
      } else {
        this.valid = false;
        this.model = {
          site: {
            name: { en: "", zh_HK: "", zh_CN: "" },
            address: { en: "", zh_HK: "", zh_CN: "" },
            district: { en: "", zh_HK: "", zh_CN: "" },
            geo: {
              coordinates: [0, 0],
              type: "Point",
            },
            type: "mall",
          },
          floor: {
            name: "",
            code: 0,
          },
          location: { en: "", zh_HK: "", zh_CN: "" },
          opening_hours: {
            remarks: { en: "", zh_HK: "", zh_CN: "" },
          },
          public_access: true,
          male_allowed: true,
          remarks: { en: "", zh_HK: "", zh_CN: "" },
          equipment: {
            changing_mats: { total: 0, cushioned: false },
            bottle_warmers: { availability: "N/A", total: 0 },
            lock: false,
            feeding_rooms: 0,
            hand_washing: 0,
            power_sockets: 0,
            seats: 0,
            remarks: { en: "", zh_HK: "", zh_CN: "" },
          },
          extra: {
            drinking_water: { cold: false, hot: false },
            air_purifier: false,
            electronic_scale: false,
            baby_wipe: false,
            baby_lotion: false,
            baby_powder: false,
            remarks: { en: "", zh_HK: "", zh_CN: "" },
          },
          services: {
            feeding_bib: false,
            milk_storage_bag: false,
            bottle_sterilizer: false,
            diaper: false,
            disposable_bib: false,
            thermometer: false,
            cooling_pack: false,
            mask: false,
            stroller: false,
            food_warming: false,
            remarks: { en: "", zh_HK: "", zh_CN: "" },
          },
          images: [],
        };
        this.bfciBadgeDate = new Date().toISOString().split("T")[0];
      }

      this.original = JSON.parse(JSON.stringify(this.model));
    },
    displayBilingual(item) {
      return `${item.zh_HK} ${item.en}`;
    },
    getImagePath(path) {
      return `${process.env.VUE_APP_API_HOST}/uploads/${path}`;
    },
    shiftImageLeft(image) {
      const originalIndex = this.model.images.indexOf(image);
      if (originalIndex > 0) {
        this.model.images = this.model.images.filter((i) => i !== image);
        this.model.images.splice(originalIndex - 1, 0, image);
      }
    },
    shiftImageRight(image) {
      const originalIndex = this.model.images.indexOf(image);
      if (originalIndex < this.model.images.length - 1) {
        this.model.images = this.model.images.filter((i) => i !== image);
        this.model.images.splice(originalIndex + 1, 0, image);
      }
    },
    deleteImage(image) {
      this.imagesToDelete.push(image.path);
      this.model.images = this.model.images.filter((i) => i !== image);
    },
    editImageAuthor(image) {
      this.imageAuthorModel = {
        index: this.model.images.indexOf(image),
        image: JSON.parse(JSON.stringify(image)),
        updateAll: false,
      };
      this.showImageAuthorEditor = true;
    },
    updateImageAuthor() {
      if (
        this.imageAuthorModel.image.author.zh_HK &&
        !this.imageAuthorModel.image.author.en
      ) {
        this.imageAuthorModel.image.author.en =
          this.imageAuthorModel.image.author.zh_HK;
      } else if (
        this.imageAuthorModel.image.author.en &&
        !this.imageAuthorModel.image.author.zh_HK
      ) {
        this.imageAuthorModel.image.author.zh_HK =
          this.imageAuthorModel.image.author.en;
      }

      if (this.imageAuthorModel.updateAll) {
        for (let i = 0; i < this.model.images.length; i++) {
          this.model.images[i].author = {
            en: this.imageAuthorModel.image.author.en,
            zh_HK: this.imageAuthorModel.image.author.zh_HK,
            zh_CN: this.imageAuthorModel.image.author.zh_CN,
          };
        }
      } else {
        this.model.images[this.imageAuthorModel.index].author = {
          en: this.imageAuthorModel.image.author.en,
          zh_HK: this.imageAuthorModel.image.author.zh_HK,
          zh_CN: this.imageAuthorModel.image.author.zh_CN,
        };
      }
      this.showImageAuthorEditor = false;
    },
    selectUploadImage() {
      this.uploadImage.selecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.uploadImage.selecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.uploadImage.file = e.target.files[0];

      let data = new FormData();
      data.append("name", this.uploadImage.file.name);
      data.append("file", this.uploadImage.file);

      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(`${process.env.VUE_APP_API_HOST}/uploads`, data, config)
        .then((response) => {
          if (response.status == 201) {
            const uploadedFile = response.data.upload;
            const filename = uploadedFile.filename;
            this.model.images.push({
              path: filename,
              panorama: false,
              author: {
                en: "",
                zh_HK: "",
                zh_CN: "",
              },
            });
          } else {
            console.error(
              `Error ${response.status}, failed to upload ${this.uploadImage.file.name}`
            );
          }
        });
    },
    addOpeningHourSession() {
      this.openingHours.push({
        weekday: false,
        sat: false,
        sun: false,
        ph: false,
        start: null,
        end: null,
      });
      this.valid = false;
    },
    deleteOpeningHourSession(session) {
      this.openingHours = this.openingHours.filter((s) => s !== session);
    },
    onSubmit() {
      // Converts coordinates to floating point numbers in JSON
      this.model.site.geo.coordinates[0] = parseFloat(
        this.model.site.geo.coordinates[0]
      );
      this.model.site.geo.coordinates[1] = parseFloat(
        this.model.site.geo.coordinates[1]
      );

      // Matches BFCI badge status to schema
      if (this.bfciBadge) {
        let badgeDate = new Date(this.bfciBadgeDate);
        badgeDate = new Date(badgeDate.getTime() + 8 * 60 * 1000);
        this.model.site.badge = {
          class: "basic",
          updated: badgeDate.toISOString(),
        };
      }

      // Converts floor code to integer
      this.model.floor.code = parseInt(this.model.floor.code);

      // Converts opening hours to required format
      this.model.opening_hours.weekday = [];
      this.model.opening_hours.sat = [];
      this.model.opening_hours.sun = [];
      this.model.opening_hours.ph = [];
      for (let session of this.openingHours) {
        if (!session.start || !session.end) {
          continue;
        }
        if (!session.weekday && !session.sat && !session.sun && !session.ph) {
          continue;
        }

        const startValue =
          parseInt(session.start.substring(0, 2)) * 100 +
          parseInt(session.start.substring(3, 5));
        const endValue =
          parseInt(session.end.substring(0, 2)) * 100 +
          parseInt(session.end.substring(3, 5));
        const sessionValue = [startValue, endValue];

        if (session.weekday) {
          this.model.opening_hours.weekday.push(sessionValue);
        }
        if (session.sat) {
          this.model.opening_hours.sat.push(sessionValue);
        }
        if (session.sun) {
          this.model.opening_hours.sun.push(sessionValue);
        }
        if (session.ph) {
          this.model.opening_hours.ph.push(sessionValue);
        }
      }

      // Converts Traditional Chinese to Simplified Chinese fields
      this.translateToSimplifiedChinese(this.model);

      // Removes equipment objects if they should be ignored
      if (this.noEquipmentData) {
        this.$delete(this.model, "equipment");
        this.$delete(this.model, "extra");
        this.$delete(this.model, "services");
      }

      // Updates timestamp
      this.model.last_updated = new Date().toISOString();

      // Creates site and facility IDs for new facility
      if (!this.model.site._id) {
        this.model.site._id = slugify(this.model.site.name.en, { lower: true });
      }
      if (!this.model._id) {
        this.model._id = slugify(
          `${this.model.site.name.en} ${this.model.floor.name} ${this.model.location.en}`,
          { lower: true }
        );
      }

      // Stringifies and emits to upstream
      this.$emit("save", JSON.stringify(this.model));
      this.$emit("delete-images", this.imagesToDelete);
    },
    translateToSimplifiedChinese(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "zh_HK")) {
        obj["zh_CN"] = sify(obj["zh_HK"]);
        return;
      }

      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === "object") {
          this.translateToSimplifiedChinese(obj[key]);
        }
      });
    },
    onRevertChanges() {
      this.copyFacilityToWorkspace(this.facility);
    },
    openInMap() {
      const newLat = this.model.site.geo.coordinates[1];
      const newLng = this.model.site.geo.coordinates[0];
      window.open(
        `https://www.google.com/maps?q=loc:${newLat},${newLng}`,
        "_blank"
      );
    },
    deleteFacility() {
      this.$emit("delete", this.facility._id);
    },
  },
};
</script>

<style scoped>
.changed >>> fieldset {
  border-width: 2px;
  border-color: #ffab00;
}

.changed >>> .v-messages__message {
  color: #ff8f00 !important;
}
</style>
