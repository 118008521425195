<template>
  <v-container fluid>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>

    <v-row>
      <v-col cols="4">
        <v-card elevation="0">
          <v-card-text class="pa-0">
            <v-toolbar dense flat class="pa-2 mt-1 mb-3">
              <v-text-field
                v-model="query"
                hide-details
                prepend-icon="mdi-magnify"
                single-line
              >
                <template v-slot:label>
                  Search {{ facilitiesCount }} listed facilities (in
                  {{ sitesCount }} sites)
                </template>
              </v-text-field>
            </v-toolbar>
            <facilities-list
              v-bind:sites="sites"
              v-on:select="onFacilitySelected"
            ></facilities-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card
          v-if="!selected && !create"
          elevation="2"
          class="content-pane ma-4 overflow-y-auto"
        >
          <v-card-text class="text-center text-h5">
            <v-container fill-height fluid style="height: 80vh">
              <v-row align="center" justify="center">
                <div>
                  <p>Search and select an existing facility to edit</p>
                  <p>or</p>
                  <v-btn color="primary" v-on:click="createNewFacility">
                    <v-icon left>mdi-plus</v-icon>
                    Add a new facility
                  </v-btn>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <facility-editor
          v-else
          v-bind:facility="selected"
          v-on:save="saveFacility"
          v-on:delete-images="deleteImages"
          v-on:delete="deleteFacility"
          class="content-pane ma-4 overflow-y-auto"
        ></facility-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

import FacilitiesList from "@/components/FacilitiesList";
import FacilityEditor from "@/components/FacilityEditor";

export default {
  name: "Facilities",
  components: {
    FacilitiesList,
    FacilityEditor,
  },
  data: () => ({
    query: "",
    queryString: "*",
    sites: [],
    sitesCount: 0,
    facilitiesCount: 0,
    selectedId: "",
    selected: null,
    create: false,
    snackbar: {
      show: false,
      color: "success",
      message: "",
    },
  }),
  mounted() {
    this.countFacilities();
    this.fetchFacilities();
  },
  watch: {
    query(q) {
      let newQuery = q;
      if (newQuery === "") {
        newQuery = "*";
      }

      if (this.queryString !== newQuery) {
        this.queryString = newQuery;
      }
    },
    queryString() {
      this.fetchFacilities();
    },
  },
  methods: {
    countFacilities() {
      axios.get(`${process.env.VUE_APP_API_HOST}/sites`).then((response) => {
        this.sitesCount = response.data.sites.length;
      });
      axios
        .get(`${process.env.VUE_APP_API_HOST}/facilities`)
        .then((response) => {
          this.facilitiesCount = response.data.facilities.length;
        });
    },
    fetchFacilities() {
      axios
        .get(
          `${process.env.VUE_APP_API_HOST}/sites/search?q=${this.queryString}`
        )
        .then((response) => {
          this.sites = response.data.sites;
        });
    },
    onFacilitySelected(id) {
      if (id === this.selectedId) {
        // Debouncing
        return;
      }

      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (!id) {
        this.selected = null;
      } else {
        axios
          .get(
            `${process.env.VUE_APP_API_HOST}/facilities/${id}/history`,
            config
          )
          .then((response) => {
            this.selected = response.data.facility;
          });
      }
      this.selectedId = id;
    },
    createNewFacility() {
      this.selected = null;
      this.selectedId = null;
      this.create = true;
    },
    deleteImages(images) {
      if (images.length > 0) {
        const token = this.$store.getters.user.authToken;
        let config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        for (let image of images) {
          axios.delete(
            `${process.env.VUE_APP_API_HOST}/uploads/${image}`,
            config
          );
        }
      }
    },
    deleteFacility(id) {
      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .delete(`${process.env.VUE_APP_API_HOST}/facilities/${id}`, config)
        .then((response) => {
          if (response.status == 200) {
            this.snackbar.color = "success";
            this.snackbar.message = "Facility deleted.";
            this.snackbar.show = true;

            this.fetchFacilities();
            this.selected = null;
            this.selectedId = null;
          } else if (response.status == 404) {
            this.snackbar.color = "error";
            this.snackbar.message = "Specified facility not found.";
            this.snackbar.show = true;
          } else {
            this.snackbar.color = "error";
            this.snackbar.message = response.data.error;
            this.snackbar.show = true;
          }
        })
        .catch((error) => {
          this.snackbar.color = "error";
          this.snackbar.message = error.message;
          this.snackbar.show = true;
        });
    },
    saveFacility(json) {
      const facility = JSON.parse(json);

      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (this.selectedId) {
        // Updates an existing facility
        axios
          .post(
            `${process.env.VUE_APP_API_HOST}/facilities/${facility._id}`,
            facility,
            config
          )
          .then((response) => {
            if (response.status == 200) {
              this.snackbar.color = "success";
              this.snackbar.message = "Facility updated.";
              this.snackbar.show = true;

              this.fetchFacilities();
              this.onFacilitySelected(facility._id);
            } else if (response.status == 404) {
              this.snackbar.color = "error";
              this.snackbar.message = "Specified facility not found.";
              this.snackbar.show = true;
            } else if (response.status == 409) {
              this.snackbar.color = "error";
              this.snackbar.message =
                "Failure on facility update due to duplicated site-floor-location combination.";
              this.snackbar.show = true;
            } else {
              this.snackbar.color = "error";
              this.snackbar.message = response.data.error;
              this.snackbar.show = true;
            }
          })
          .catch((error) => {
            this.snackbar.color = "error";
            this.snackbar.message = error.message;
            this.snackbar.show = true;
          });
      } else {
        // Creates a new facility
        axios
          .put(`${process.env.VUE_APP_API_HOST}/facilities`, facility, config)
          .then((response) => {
            if (response.status == 201) {
              this.snackbar.color = "success";
              this.snackbar.message = "Facility created.";
              this.snackbar.show = true;

              this.create = false;
              this.fetchFacilities();
            } else if (response.status == 409) {
              this.snackbar.color = "error";
              this.snackbar.message =
                "Failure on facility update due to duplicated site-floor-location combination.";
              this.snackbar.show = true;
            } else {
              this.snackbar.color = "error";
              this.snackbar.message = response.data.error;
              this.snackbar.show = true;
            }
          })
          .catch((error) => {
            this.snackbar.color = "error";
            this.snackbar.message = error.message;
            this.snackbar.show = true;
          });
      }
    },
  },
};
</script>

<style scoped>
.content-pane {
  height: 85vh;
}
</style>
