<template>
  <v-list rounded class="overflow-y-auto" style="max-height: 85vh">
    <v-list-item-group v-model="selected" v-for="s in suggestions" :key="s.ref">
      <v-list-item
        :value="s.ref"
        v-on:click="$emit('select', s)"
        link
      >
        <v-list-item-avatar :color="getIconColor(s)">
          <v-icon color="white">{{ getIcon(s) }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ s.facility.site.name.zh_HK }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ s.facility.site.name.en }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>
            {{ formatDateTime(s.submitted) }}
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "FacilityEditSuggestionsList",
  data: () => ({
    selected: null,
  }),
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
  },
  watch: {
    selected(suggestion) {
      if (!suggestion) {
        this.$emit("select", null);
      }
    },
  },
  methods: {
    formatDateTime(date) {
      return moment(date).tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm:ss");
    },
    getIconColor(suggestion) {
      if (suggestion.approved) {
        return "green";
      } else {
        if (suggestion.processed) {
          // Rejected
          return "grey";
        } else {
          return "orange";
        }
      }
    },
    getIcon(suggestion) {
      if (suggestion.facility._id) {
        // Exisiting facility
        return "mdi-pencil";
      } else {
        // New facility
        return "mdi-map-marker-plus";
      }
    },
  },
};
</script>
