<template>
  <v-container fluid>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>

    <v-row justify="center">
      <v-col cols="6">
        <v-row class="pa-4">
          <h3>Important Notes</h3>
          <ul class="pt-1">
            <li>
              Banner image must be a PNG file of 1680 pixels in width, and 400
              pixels in height. Get the template image
              <a href="bfGPS_In-app_Banner_Spec.png" target="_blank">here</a>.
            </li>
            <li>
              If no uploaded banner is enabled, the default placeholder will be
              shown in the app.
            </li>
            <li>
              If more than one uploaded banners are enabled, one of them will be
              shown in the app randomly upon each app launch.
            </li>
            <li>
              Changes made on this page take effect immediately and are
              irreversible. <em>Proceed with caution.</em>
            </li>
          </ul>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex child-flex"
            v-for="i in banners"
            :key="i._id"
          >
            <v-card>
              <v-img
                :src="getImagePath(i.image_path)"
                class="grey darken-4"
                aspect-ratio="4.2"
                contain
              >
              </v-img>

              <v-card-text>
                <div>
                  <b>
                    {{
                      i.enabled
                        ? i.baby_tracker
                          ? "Banner is enabled in baby journal view"
                          : "Banner is enabled in search facilities view"
                        : "Banner is disabled"
                    }}
                  </b>
                </div>
                <div v-if="i.enabled">
                  {{
                    i.schedule.end
                      ? `Showing from ${formatDateTime(
                          i.schedule.start
                        )} to ${formatDateTime(i.schedule.end)}`
                      : `Showing from ${formatDateTime(i.schedule.start)}`
                  }}
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" v-on:click="editBanner(i)">
                  EDIT BANNER
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" class="d-flex child-flex">
            <v-card min-height="80" elevation="0">
              <v-container fluid fill-height>
                <v-row align="center" justify="center">
                  <v-btn
                    text
                    color="primary"
                    :loading="uploadImage.selecting || uploadImage.uploading"
                    v-on:click="selectUploadImage"
                  >
                    <v-icon left>mdi-upload</v-icon>
                    UPLOAD NEW BANNER IMAGE
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/png"
                    v-on:change="onFileChanged"
                  />
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="showBannerEditor" max-width="480">
      <banner-editor
        v-bind:selected="selectedBanner"
        v-on:delete="deleteBanner"
        v-on:update="updateBanner"
        v-on:close="showBannerEditor = false"
      ></banner-editor>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";

import BannerEditor from "../components/BannerEditor.vue";

export default {
  name: "Banners",
  components: {
    BannerEditor,
  },
  data: () => ({
    snackbar: {
      show: false,
      color: "success",
      message: "",
    },
    banners: [],
    selectedBanner: null,
    showBannerEditor: false,
    uploadImage: {
      selecting: false,
      uploading: false,
      file: null,
    },
  }),
  mounted() {
    this.fetchBanners();
  },
  watch: {},
  methods: {
    formatDateTime(date) {
      return moment(date).tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm:ss");
    },
    fetchBanners() {
      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .get(`${process.env.VUE_APP_API_HOST}/banners/all`, config)
        .then((response) => {
          this.banners = response.data.banners;
        });
    },
    getImagePath(path) {
      return `${process.env.VUE_APP_API_HOST}/uploads/${path}`;
    },
    editBanner(banner) {
      this.selectedBanner = banner;
      this.showBannerEditor = true;
    },
    async deleteBanner(banner) {
      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const id = banner._id;
      const imagePath = banner.image_path;

      await axios.delete(
        `${process.env.VUE_APP_API_HOST}/banners/${id}`,
        config
      );
      await axios.delete(
        `${process.env.VUE_APP_API_HOST}/uploads/${imagePath}`,
        config
      );

      this.showBannerEditor = false;
      this.selectedBanner = null;

      this.fetchBanners();
    },
    async updateBanner(banner) {
      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const id = banner._id;

      await axios.post(
        `${process.env.VUE_APP_API_HOST}/banners/${id}`,
        banner,
        config
      );

      this.showBannerEditor = false;
      this.selectedBanner = null;

      this.fetchBanners();
    },
    selectUploadImage() {
      this.uploadImage.selecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.uploadImage.selecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.uploadImage.file = e.target.files[0];

      let data = new FormData();
      data.append("name", this.uploadImage.file.name);
      data.append("file", this.uploadImage.file);

      const token = this.$store.getters.user.authToken;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(`${process.env.VUE_APP_API_HOST}/uploads`, data, config)
        .then((response) => {
          if (response.status == 201) {
            const uploadedFile = response.data.upload;
            const filename = uploadedFile.filename;

            const token = this.$store.getters.user.authToken;
            let config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

            axios
              .put(
                `${process.env.VUE_APP_API_HOST}/banners`,
                {
                  enabled: false,
                  image_path: filename,
                  link: {
                    android: "https://www.facebook.com/bfGPS",
                    ios: "fb://profile/100648884972860",
                    fallback: "https://www.facebook.com/bfGPS",
                  },
                  baby_tracker: false,
                },
                config
              )
              .then(() => {
                this.fetchBanners();
              });
          } else {
            console.error(
              `Error ${response.status}, failed to upload ${this.uploadImage.file.name}`
            );
          }
        });
    },
  },
};
</script>
