<template>
  <v-card v-if="model">
    <v-img :src="getImagePath(model.image_path)"></v-img>
    <v-card-title>Edit Banner</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="model.enabled"
                label="Enabled"
                :hint="
                  model.enabled
                    ? 'The banner will be shown to app users according to the schedule'
                    : 'The banner will not be shown to app users'
                "
                persistent-hint
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="pb-6">
            <v-col cols="12">
              <v-switch
                v-model="model.baby_tracker"
                label="Baby journal banner"
                :hint="
                  model.baby_tracker
                    ? 'The banner will be shown in baby journal view only'
                    : 'The banner will be shown in search facilities view only'
                "
                persistent-hint
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.link.android"
                :rules="[(v) => !!v || 'Required']"
                label="Android Link"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.link.ios"
                :rules="[(v) => !!v || 'Required']"
                label="iOS Link"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.link.fallback"
                :rules="[(v) => !!v || 'Required']"
                label="Fallback Link"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="showStartDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Start Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="showStartDatePicker = false"
                  :max="endDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="showEndDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="End Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    clearable
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="startDate"
                  @input="showEndDatePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="red" v-on:click="$emit('delete', selected)">
        Delete
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="accent" v-on:click="onUpdate">Update</v-btn>
      <v-btn text color="accent" v-on:click="$emit('close')">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "BannerEditor",
  props: {
    selected: Object,
  },
  data: () => ({
    model: null,
    valid: false,
    startDate: null,
    endDate: null,
    showStartDatePicker: false,
    showEndDatePicker: false,
  }),
  watch: {
    selected(banner) {
      this.copyBannerToWorkspace(banner);
    },
  },
  mounted() {
    this.copyBannerToWorkspace(this.selected);
  },
  methods: {
    copyBannerToWorkspace(banner) {
      this.startDate = null;
      this.endDate = null;
      if (banner) {
        this.model = JSON.parse(JSON.stringify(banner));
        if (banner.schedule.start) {
          this.startDate = moment(banner.schedule.start)
            .tz("Asia/Hong_Kong")
            .format("YYYY-MM-DD");
        }
        if (banner.schedule.end) {
          this.endDate = moment(banner.schedule.end)
            .tz("Asia/Hong_Kong")
            .format("YYYY-MM-DD");
        }
      }
    },
    onUpdate() {
      if (this.startDate) {
        this.model.schedule.start = `${this.startDate}T00:00:00+08:00`;
      }
      if (this.endDate) {
        this.model.schedule.end = `${this.endDate}T23:59:59+08:00`;
      } else {
        this.model.schedule.end = null;
      }
      this.model.last_updated = new Date().toISOString();
      this.$emit("update", JSON.parse(JSON.stringify(this.model)));
    },
    getImagePath(path) {
      return `${process.env.VUE_APP_API_HOST}/uploads/${path}`;
    },
  },
};
</script>
