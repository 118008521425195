<template>
  <v-list rounded class="overflow-y-auto" style="max-height: 80vh">
    <v-list-item-group v-model="selected" v-for="site in sites" :key="site._id">
      <!-- Multiple facilities in the same site -->
      <v-list-group v-if="site.facilities.length > 1">
        <!-- Site -->
        <template v-slot:activator>
          <v-list-item-avatar :color="getSiteTypeColor(site.site.type)">
            <v-icon color="white">{{ getSiteTypeIcon(site.site.type) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ site.site.name.zh_HK }}</v-list-item-title>
            <v-list-item-subtitle>{{ site.site.name.en }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <!-- Facilities in site -->
        <v-list-item
          v-for="facility in site.facilities"
          :key="facility._id"
          :value="facility._id"
          v-on:click="$emit('select', facility._id)"
          link
        >
          <v-list-item-avatar>{{ facility.floor.name }}</v-list-item-avatar>
          <v-list-item-title>{{ facility.location.zh_HK }}</v-list-item-title>
          <v-list-item-subtitle>{{
            facility.location.en
          }}</v-list-item-subtitle>
        </v-list-item>
      </v-list-group>

      <!-- Single facility -->
      <v-list-item
        v-else
        :value="site.facilities[0]._id"
        v-on:click="$emit('select', site.facilities[0]._id)"
        link
      >
        <v-list-item-avatar :color="getSiteTypeColor(site.site.type)">
          <v-icon color="white">{{ getSiteTypeIcon(site.site.type) }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ site.site.name.zh_HK }}</v-list-item-title>
          <v-list-item-subtitle>{{ site.site.name.en }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "FacilitiesList",
  data: () => ({
    selected: null,
  }),
  props: {
    sites: {
      type: Array,
      required: true,
    },
  },
  watch: {
    selected(id) {
      if (!id) {
        this.$emit("select", null);
      }
    },
  },
  methods: {
    getSiteTypeColor(type) {
      switch (type) {
        case "community":
          return "#4CAF50";
        case "mall":
          return "#2196F3";
        case "restaurant":
          return "#FFB300";
        case "transport":
          return "#C62828";
        case "clinical":
          return "#EF5350";
        default:
          return "#607D8B";
      }
    },
    getSiteTypeIcon(type) {
      switch (type) {
        case "community":
          return "mdi-bank";
        case "mall":
          return "mdi-shopping";
        case "restaurant":
          return "mdi-silverware";
        case "transport":
          return "mdi-subway-variant";
        case "clinical":
          return "mdi-hospital-box";
        default:
          return "mdi-city";
      }
    },
  },
};
</script>
