import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';

import firebase from 'firebase/app';
import 'firebase/auth';

Vue.config.productionTip = false;

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
});

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    firebase.auth().currentUser.getIdTokenResult(true)
      .then((result) => {
        user.role = result.claims.role;
        user.authToken = result.token;
        store.dispatch('fetchUser', user);
        if (store.getters.user.signedIn) {
          router.replace({ name: 'Home' });
        }
      });
  } else {
    store.dispatch('fetchUser', null);
  }
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
