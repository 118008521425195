import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import Banners from '../views/Banners';
import Facilities from '../views/Facilities';
import FacilityEditSuggestions from '../views/FacilityEditSuggestions';
import FacilityReviews from '../views/FacilityReviews';
import Login from '../views/Login';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Facilities,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/suggest',
    name: 'Facility Edit Suggestions',
    component: FacilityEditSuggestions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reviews',
    name: 'Facility Reviews',
    component: FacilityReviews,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/banners',
    name: 'In-App Banners Editor',
    component: Banners,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.user.signedIn) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
